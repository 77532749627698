<!-- Virtual View -->

<template>
  <div class="virtualView" v-if="loaded">
    <dualTemplate
      :modal="modal"
      :dualTipo="dualTipo"
       persistent>

        <template v-slot:controles="{}">
          <div>

            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>

            <!-- Filtro -->
            <v-sheet :elevation="4">
              <div class="contenedor" style="width:750px">
                <div class="columna">

                  <div v-for="(vct, index) in ct" :key="index">

                    <!-- TEXTFIELD -->
                    <uiText
                      v-if="!vct[4] || (!vct[4].comp && !vct[4].compVirtual)"
                      v-model="vct[2]"
                      :label="vct[1]"
                      readonly>
                    </uiText>

                    <!-- COMPONENTE compVirtual -->
                    <div v-else-if="vct[4].compVirtual">
                      <div v-if="vct[4].compVirtual.type=='btn'">
                        <v-btn
                          v-bind="$cfe.default.btra.std"
                          @click="launchFnBtn(index)">
                            <v-icon
                              v-bind="$cfe.default.btra.std.icons">
                                {{ vct[4].compVirtual.icono }}
                            </v-icon>
                        </v-btn>
                      </div>

                      <div v-else-if="vct[4].compVirtual.type=='textarea'">
                        <v-textarea
                          v-bind="$textarea"
                          v-model="vct[2]"
                          :label="vct[1]"
                          no-resize
                          rows="3"
                          :readonly="!vct[4].compVirtual.edicion? true : false"
                          :disabled="!vct[4].compVirtual.edicion? true : false">
                        </v-textarea>
                      </div>
                    </div>

                    <!-- COMPONENTES -->
                    <div v-else-if="vct[4].comp.type=='obs3'">
                      <uiObs3
                        :record="record"
                        :schema="ct"
                        :schemaComponente="vct"
                        readonly>
                      </uiObs3>
                    </div>

                    <!-- CHECKBOX -->

                    <!-- RADIO INPUT -->

                    <!-- SELECT -->

                    <!-- PICK -->
                  </div>

                </div>
              </div>
            </v-sheet>
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import dualTemplate from "@/components/dualTemplate";

  export default {
    mixins: [mixM],
    components: { baseHeader, dualTemplate },
    props: {},

    data() {
      return {
        stIni: {
          api:this.ctrlCt[4].comp.mView,
          titulo:"Datos",
          name:"virtualView",
          mView:'',
          pView:[]
        },

        dualTipo:'modal'
      };
    },



    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        if (this.ctrlCt[4] && this.ctrlCt[4].comp && this.ctrlCt[4].comp.tipoView==='FA') this.dualTipo= '';
      },


      iniDataApi() {
        this.apiArgs.sch= null;
      },


      // función para obtener el record de un mto virtual tipo 'FA'.
      // Hay de definir como acción del master 'ver' y
      // añadir la propiedad stVer en el 'comp' del ct,
      // indicando la función a ejecutar. Esa función devuelve los
      // parámetros de la llamada a la API que aquí ejecutamos
      /* async stVer() {
        if (!this.ctrlCt[4] ||
            !this.ctrlCt[4].comp ||
            this.ctrlCt[4].comp.tipoView!=='FA' ||
            !this.ctrlCt[4].comp.stVer) return super.stVer();

        let param= this.ctrlCt[4].comp.stVer();
        console.log("stVer virtualView *************************:", param);
        return await this.$store.dispatch(this.storeName + "/ver", { param, origen:'virtualView' });
      }, */


      launchFnBtn(btn) {
        console.log('iniDataParticular - launch:::', this.ctrlCt, btn);

        this.ctrlCt[4].comp.ctView[btn][4].compVirtual.fn(this.ct);
      },


     },


    computed: {
      sch() {
        return this.ctrlCt[4].comp.ctView;
      },

    },

  };
</script>


